import React from "react"
import { Table } from "reactstrap"
import { Link } from "gatsby"
import * as R from "ramda"
import styled from "styled-components"
import Layout from "../components/ListLayout"
import { lighten } from "polished"
import SEO from "../components/seo"

const { slugify_chinese } = require("../util/utilityFunctions")

const StyledLink = styled(Link)`
  color: ${props => props.theme.titleFontColor};
  &:hover {
    color: ${props => lighten(0.2, props.theme.titleFontColor)};
    text-decoration: none;
  }
`

const SeriesPage = props => {
  const { groupBySeries } = props.pageContext

  return (
    <Layout>
      <SEO title="系列文" pathname={props.location.pathname} />
      <Table responsive striped>
        <thead>
          <tr>
            <th>#</th>
            <th>系列</th>
            <th>篇數</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupBySeries).sort().map((seriesTitle, index) => {
            let firstPost = groupBySeries[seriesTitle][0]
            let slug = R.path(["node", "fields", "slug"], firstPost)
            return (
              <tr key={seriesTitle}>
                <th style={{ width: "60px" }}>{index + 1}</th>
                <th>
                  <StyledLink
                    to={`/series/${slugify_chinese(seriesTitle)}/${slug}`}
                  >
                    {seriesTitle}
                  </StyledLink>
                </th>
                <th style={{ width: "60px" }}>
                  {groupBySeries[seriesTitle].length}
                </th>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Layout>
  )
}

export default SeriesPage
